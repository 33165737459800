.daily-logs {
  width: 100%;
  border-collapse: collapse;
}

.daily-logs th,
.daily-logs td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.daily-logs th {
  background-color: #f2f2f2;
  font-weight: bold;
}
